

.chatbox_container_card {
    border-radius: 0px !important;
    background-color: var(--chat-box) !important;
}
.chatbox_footer_card {
    background-color: var(--chat-box) !important;
}


/* chat feed */
.chatfeed_container_card {
    border-radius: 0px !important;
    background-color: var(--chat-box) !important;
    box-shadow: none !important;
    /* height: 100% !important;
    max-height: 100% !important;
    overflow-y: auto !important; */
}
.chatbox_card_content {
    padding: 0px !important;
}

/* mymessage */
.my_message_end {
    display: flex;
    justify-content: flex-end;
}
.my_message_start {
    display: flex;
    justify-content: flex-start;
}
.my_message_container {
    max-width: 100% !important;
    width: 100% !important;
    overflow: hidden;
    display: inline-block;
}
.message_container {
    background-color: var(--my-message) !important;
    border-radius: 5px;
    max-width: 90% !important;
    overflow: hidden;
    float: right !important;
    padding: 10px;
}
.my_message_text{
    width: 100%;
    word-wrap: break-word;
}
.my_message_attchment_container {
    background-color: aliceblue !important;
    border-radius: 5px !important;
}
/* post message */
.post_message_end {
    display: flex;
    justify-content: flex-end;
}
.post_message_start {
    display: flex;
    justify-content: flex-start;
}
.post_message_container {
    max-width: 100% !important;
    width: 100% !important;
    overflow: hidden;
    display: inline-block;
}
.post_message_container2 {
    border-radius: 5px;
    max-width: 90% !important;
    overflow: hidden;
    float: right !important;
}
.post_message_text{
    width: 100%;
    word-wrap: break-word;
}
.post_message_attchment_container {
    background-color: aliceblue !important;
    border-radius: 5px !important;
}
.post_replay_main_container{
    padding: 10px;
    box-shadow: none !important;
}
.post_replay_container{
    background-color: #f5f7f9 !important;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
}
/* received */
.received_message_container {
    overflow: hidden !important;
    width: 100%;
    max-width: 100%;
    display: inline-block;
}
.received_message_wrapper {
    background-color: var(--received-message) !important;
    max-width: 90% !important;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;

}
.chat-header-container {
    min-height: 50px !important;
}
/* footer */
.error_message_container{
padding: 10px;
padding-left: 20px;
border-radius: 0px !important;
}
.attach_file_container {
    min-width: 300px;
    width: 30%;
    background-color: var(--chat-box) !important;
    padding: 10px;
    border-radius: 10px;
}
.attach_file_Loader {
    min-width: 300px;
    width: 30%;
}
.chat_feed_scroll {
    margin-bottom: 10px;
}
/* tabs */
.tab_name{
    cursor: pointer !important;
}
.selected_tab {
    border-bottom: 3px solid blue !important;
}
.on_hover_text :hover{
text-decoration: underline !important;
}