.convlist_container_card{
    min-width: 320px;
    max-width: 320px;
    border-radius: 0px !important;
    height: 100%;
    background-color: var(--conversation-list) !important;
}
.convlist_container_cardcontent{
    height: 100%;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 0px !important;
}
.convlist_container_box{
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: var(--conversation-list-boxshadow) !important;
}
.convlist_body{
    flex:1;
    overflow: auto;
}
/* ConversationListHeader */
