
.vertical_nav_icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px !important;
    overflow: hidden;
}
.vertical_appbar{
    height: 100%;
    flex-direction: column;
    background-color: var(--vertical-appbar) !important;
}
.vertical_toolbar{
    flex-direction: column;
}
.vertical_nav_icon_name{
    font-size: 0.7rem !important;
}