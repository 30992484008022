* {
  box-sizing: border-box;
}
.flex {
  display: flex;
}
.d-flex{
  display: flex;
}
.flex-1{
  flex: 1 !important;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-start {
  justify-content: flex-start;
}
.flex-end {
  justify-content: flex-end !important;
}
.justify-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end;
}
.space-evenly {
  justify-content: space-evenly;
}
.space-around {
  justify-content: space-around;
}
.space-between {
  justify-content: space-between;
}
/* margins */
.m-0 {
  margin: 0px !important;
}
.ms-1 {
  margin-left: 16px;
}
.ms-2 {
  margin-left: 32px;
}
.ms-3 {
  margin-left: 48px;
}
.ms-4 {
  margin-left: 64px;
}
.ms-5 {
  margin-left: 80px;
}

.me-1 {
  margin-right: 16px;
}
.me-2 {
  margin-right: 32px;
}
.me-3 {
  margin-right: 48px;
}
.me-4 {
  margin-right: 64px;
}
.me-5 {
  margin-right: 80px;
}

.mt-1 {
  margin-top: 16px;
}
.mt-2 {
  margin-top: 32px;
}
.mt-3 {
  margin-top: 48px;
}
.mt-4 {
  margin-top: 64px;
}
.mt-5 {
  margin-top: 80px;
}

.mb-1 {
  margin-bottom: 16px;
}
.mb-2 {
  margin-bottom: 32px;
}
.mb-3 {
  margin-bottom: 48px;
}
.mb-4 {
  margin-bottom: 64px;
}
.mb-5 {
  margin-bottom: 80px;
}
/* padding */
.p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: 16px !important;
}
.p-2 {
  padding: 32px;
}
.p-3 {
  padding: 48px;
}
.p-4 {
  padding: 64px;
}
.p-5 {
  padding: 80px;
}
/* cursor */
.cursor-pointer{
  cursor: pointer;
}
/* overflow */
.overflow-hidden{
  overflow: hidden;
}
.overflow-auto{
  overflow: auto;
}
.overflow-scroll{
  overflow: scroll;
}
.overflowY-scroll{
  overflow-y: scroll;
}
/* width */
.w-100{
  width:100%
}
.w-100vw{
  width:100vw
}
/* height */
.h-100{
  height:100% !important;
}
.h-100vh{
  height:100vh
}
/* border */
.br-0{
  border-radius: 0px;
}
/* text */
.word-break-all{
  word-break: break-all;
}
/* colors */
.bg-white {
  background-color: #fff !important;
}