.container{
    height: 100vh
}
.chat_page_container{
    flex: 1;
    overflow: hidden;
}
.chat_page_container2{
    height: 100%;
}
.chat_page_item_no_scroll{
    height: 100%;
    max-height: 100%;
}
.chat_page_item_scroll{
    overflow: auto;
    max-height: 100%;
}

.vertical_appbar{
    max-width: 68px;
    min-width: 68px;
}