:root{
    --vertical-appbar: #ebebeb;
    --conversation-list: #f0f0f0;
    --conversation-list-boxshadow: 0 4px 8px rgba(0,0,0,0.07);
    --conversation-list-border: 1px solid rgba(0, 0, 0, 3);
    --chat-box: #f5f5f5;
    --my-message: #dfe6ed;
    --received-message: #fff;
}

.boxShadow_white{
    box-shadow: 0 1px 3px #dcdfe2;
}
